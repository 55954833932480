var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-4 my-4"},[_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Rechercher un utilisateur","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"mt-4 elevation-10",attrs:{"headers":_vm.headers,"items":_vm.allUsers,"loading":_vm.loading,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.useOTP",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.useOTP ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.useOTP ? 'Oui' : 'Non'))])]}},{key:"item.electron",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.electron ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.electron ? item.electron : 'Non'))])]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.mobile ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.mobile ? item.mobile : 'Non'))])]}},{key:"item.web",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.web ? 'green' : 'red',"text-color":"white"}},[_vm._v(_vm._s(item.web ? item.web : 'Non'))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('v-chip',{attrs:{"text-color":"white"}},[_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString().replace(',', ''))+" ")]):_vm._e()]}},{key:"item.lastUsedAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"white"}},[_vm._v(" "+_vm._s(new Date(_vm.lastUsedAt(item)).toLocaleString().replace(',', ''))+" ")])]}},{key:"item.vlastUsedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userAgent(item))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }