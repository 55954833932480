import http from "@/http-common";

class LogService {
  getAll() {
    const url = 'log';

    return http.get(url)
    .then((response) => {
      const { data } = response;
      
      if (data) {
        return data;
      }
      else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new LogService();