const env = {};

env.config = {
  api_url: "https://log.compagnon.production.k2project.fr/api/v1/",
  //api_url: "http://localhost:2005/api/v1/",
  cdn_url: "https://xxx"
};

env.install = function (Vue) {
  Vue.prototype.$env = env.config;
}

module.exports = env;