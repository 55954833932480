<template>
  <div>
    <v-item-group>
      <v-container>
        <v-select
          v-model="period"
          :items="periods"
          @change="onPeriodChange"
        ></v-select>
        <v-row>
          <v-col cols="12" md="3">
            <Counter title="Utilisateurs" :count="user.length" />
          </v-col>
          <v-col cols="12" md="3">
            <Counter title="Appels depuis Compagnon" :count="callLogs.length" />
          </v-col>
          <v-col cols="12" md="3">
            <Counter title="Lancement d'application" :count="mountedLogs.length" />
          </v-col>
          <v-col cols="12" md="3">
            <Counter title="Erreurs" :count="errorLogs.length" />
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
    <v-item-group>
      <v-container>
        <Chart :load="load" :data="data" :options="options" />
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import Counter from './Counter.vue';
import Chart from './Chart.vue';

import { mapGetters } from 'vuex'

export default {
  name: 'Statistique',

  components: { Counter, Chart },
  
  computed: {
    ...mapGetters({
      getCallLogs: 'log/getCallLogs',
      getMountedLogs: 'log/getMountedLogs',
      getErrorLogs: 'log/getErrorLogs',
    })
  },

  data: function() {
    return {
      load: false,
      period: 'today',
      periods: [
        {
          text: 'Aujourd\'hui',
          value: 'today'
        },
        {
          text: '7 derniers jours',
          value: 'week'
        },
        {
          text: '31 derniers jours',
          value: 'month'
        },
        {
          text: '365 derniers jours',
          value: 'year'
        },
      ],
      user: [],
      callLogs: [],
      mountedLogs: [],
      errorLogs: [],
      data: {
        labels: [],
        datasets: [{
            label: 'Nombre d\'erreur',
            backgroundColor: '#c00050',
            data: []
          },
          {
            label: 'Nombre d\'utilisateurs',
            backgroundColor: '#2196f3',
            data: []
          },
          {
            label: 'Nombre d\'appels',
            backgroundColor: '#fee400',
            data: []
          },          
        ]
      },
      options: { responsive: true, maintainAspectRatio: false }
    }
  },
  
  mounted: function() {
    this.load = true;
    this.getData();
  },

  methods: {
    onPeriodChange: function() {
      this.load = false;
      
      // setTimout pour que le load change avec la fin de fonction et demount/remount le graph
      setTimeout(() => {
        this.data.labels = [];
        this.data.datasets[0].data = []
        this.data.datasets[1].data = []
        this.data.datasets[2].data = []
        this.getData();
      }, 10);
    },
    getData: function() {
      // Récupération depuis vuex puis filtre sur la periode selectionné
      const now = new Date();
      
      let filterFn = () => { return true; }
      switch (this.period) {
        case 'today':
        default:
          filterFn = (el) => {
            return new Date(el.createdAt).toDateString() == now.toDateString();
          }
          break;
        case 'week':
          now.setDate(now.getDate()-7);
          filterFn = (el) => {
            return new Date(el.createdAt) >= now;
          }          
          break;
        case 'month':
          now.setDate(now.getDate()-30);
          filterFn = (el) => {
            return new Date(el.createdAt) >= now;
          }          
          break;
        case 'year':
          now.setDate(now.getDate()-365);
          filterFn = (el) => {
            return new Date(el.createdAt) >= now;
          }          
          break;
      }
      this.callLogs = this.getCallLogs.filter(filterFn);
      this.mountedLogs = this.getMountedLogs.filter(filterFn);

      this.user = this.mountedLogs.map(item => item.user_id).filter((value, index, self) => self.indexOf(value) === index);

      this.errorLogs = this.getErrorLogs.filter(filterFn);

      this.generateChart();
    },
    generateChart: function() {
      this.load = false;
      
      switch (this.period) {
        case 'today':
        default:
          this.generateChartDataForToday();
          break;
        case 'week':
          this.generateChartDataForWeek();  
          this.reverseData(); 
          break;
        case 'month':
          this.generateChartDataForMonth();
          this.reverseData();

          break;
        case 'year':
          this.generateChartDataForYear();
          this.reverseData();    
          break;
      }

      this.load = true;
    },
    getChartData: function(filterFn) {
      const call = this.callLogs.filter(filterFn);
      
      this.data.datasets[2].data.push(call.length);

      // Il faut compter le nombre d'utilisateur ce jour
      const mounted = this.mountedLogs.filter(filterFn);

      // Rendre le tableau unique par utilisateur
      const mountedUniq = mounted.map(item => item.user_id).filter((value, index, self) => self.indexOf(value) === index);
      this.data.datasets[1].data.push(mountedUniq.length);

      // Il faut compter le nombre de call sur ce jour
      const error = this.errorLogs.filter(filterFn);
      
      this.data.datasets[0].data.push(error.length);
    },
    generateChartDataForToday: function() {
      for (let index = 0; index < new Date().getHours() + 1; index++) {
        this.data.labels.push(index.toString());

        this.getChartData(el => {
          return new Date(el.createdAt).getHours() == index;
        });
      }
    },
    generateChartDataForWeek: function() {
      const d = new Date();
      for (let index = 0; index < 7; index++) {
        this.data.labels.push(d.toLocaleDateString().substring(0, 5));

        this.getChartData(el => {
          return new Date(el.createdAt).toDateString() == d.toDateString();
        });
        
        d.setDate(d.getDate()-1);
      }
    },
    generateChartDataForMonth: function() {
      const d = new Date();
      for (let index = 0; index < 31; index++) {
        this.data.labels.push(d.toLocaleDateString().substring(0, 5));

        this.getChartData(el => {
          return new Date(el.createdAt).toDateString() == d.toDateString();
        });
        
        d.setDate(d.getDate()-1);
      }
    },
    generateChartDataForYear: function() {
      const d = new Date();
      for (let index = 0; index < 12; index++) {
        this.data.labels.push(d.toLocaleDateString().substring(3, 10));

        this.getChartData(el => {
          return new Date(el.createdAt).getMonth() == d.getMonth();
        });
        
        d.setMonth(d.getMonth()-1);
      }
    },
    reverseData: function() {
      this.data.labels.reverse();
      this.data.datasets[0].data.reverse();
      this.data.datasets[1].data.reverse();
      this.data.datasets[2].data.reverse();
    }
  }
}
</script>
<style>

</style>