<template>
  <v-card
    class="center-xy"
    dark
    height="200"
  >
    <div>
      <div class="text-h2 flex-grow-1 text-center">{{ count }}</div>
      <div class="text-h6 flex-grow-1 text-center">{{ title }}</div>
    </div>
    
  </v-card>
</template>

<script>
export default {
  name: 'Counter',

  props: {
    count: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    }
  },
}
</script>
<style>

</style>