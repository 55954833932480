/**
 * C'est dans ce fichier que sont déclarer les machines à états/store VueX
 * Pour chaque store, il faut importer son modules et le déclarer dans les modules de l'export
 */
import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import upload from './modules/upload'
import users from './modules/users'
import log from './modules/log'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    upload,
    users,
    log
  }
});