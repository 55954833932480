<template>
  <div class="mx-4 my-4">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Rechercher un utilisateur"
      class="mx-4"
      hide-details
      single-line
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="allUsers"
      :loading="loading"
      :items-per-page="-1"
      class="mt-4 elevation-10"
    >
      <template v-slot:item.useOTP="{ item }">
        <v-chip
          :color="item.useOTP ? 'green' : 'red'"
          text-color="white"
        >{{ item.useOTP ? 'Oui' : 'Non' }}</v-chip>
      </template>
      <template v-slot:item.electron="{ item }">
        <v-chip
          :color="item.electron ? 'green' : 'red'"
          text-color="white"
        >{{ item.electron ? item.electron : 'Non' }}</v-chip>
      </template>
      <template v-slot:item.mobile="{ item }">
        <v-chip
          :color="item.mobile ? 'green' : 'red'"
          text-color="white"
        >{{ item.mobile ? item.mobile : 'Non' }}</v-chip>
      </template>
      <template v-slot:item.web="{ item }">
        <v-chip
          :color="item.web ? 'green' : 'red'"
          text-color="white"
        >{{ item.web ? item.web : 'Non' }}</v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <v-chip
          v-if="item.createdAt"
          text-color="white"
        >
          {{ new Date(item.createdAt).toLocaleString().replace(',', '') }}
        </v-chip>
      </template>
      <template v-slot:item.lastUsedAt="{ item }">
        <v-chip
          text-color="white"
        >
          {{ new Date(lastUsedAt(item)).toLocaleString().replace(',', '') }}
        </v-chip>
      </template>
      <template v-slot:item.vlastUsedAt="{ item }">
          {{ userAgent(item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import UAParser from 'ua-parser-js'

export default {
  name: 'User',
  
  computed: {
    ...mapGetters({
      getAll: 'users/getAll',
      getAllLogs: 'log/getAllLogs',
    }),
    allUsers() {
      if (this.search == "") {
        return this.getAll;
      }
      else {
        return this.getAll.filter(el => {
          return el.name.toLowerCase().includes(this.search.toLowerCase())
          || el.email.toLowerCase().includes(this.search.toLowerCase())
          || el.user_id.toString().toLowerCase().includes(this.search.toLowerCase())
        })
      }
    },
    lastUsedAt() {
      return item => {
        const logs = this.getAllLogs.filter(el => el.user_id == item.user_id && el.title == "appMounted");
        const lastLog = logs.at(-1);
        return lastLog ? lastLog.createdAt : item.createdAt
      }
    },
    userAgent() {
      return item => {
        const logs = this.getAllLogs.filter(el => el.user_id == item.user_id && el.title == "appMounted");
        const lastLog = logs.at(-1);

        if (lastLog) {
          const ua = UAParser(typeof lastLog.data == "string" ? JSON.parse(lastLog.data).userAgent : lastLog.data.userAgent);
          return `${ua.os.name} ${ua.os.version}`
        }

        return 'Aucune information'
      }
    }
  },

  data: function() {
    return {
      loading: true,
      search: "",
      headers: [
        { text: 'ID', value: 'user_id' },
        { text: 'Identité', value: 'name' },
        { text: 'E-mail', value: 'email' },
        { text: 'OTP', value: 'useOTP' },
        { text: 'Lancement d\'application', value: 'log_appMountedCounter' },
        { text: 'Première connexion', value: 'createdAt' },
        { text: 'Dernière activité', value: 'lastUsedAt' },
        { text: 'Version de la dernière connexion', value: 'vlastUsedAt' },
        { text: 'Electron', value: 'electron' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Web', value: 'web' }
      ]
    }
  },
  
  mounted: function() {
    this.$store.dispatch('users/getAll').finally(() => {
      this.loading = false;
    });
  },

  methods: {

  }
}
</script>
<style>

</style>