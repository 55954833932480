import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login/Login.vue'
import Statistiques from '@/views/Statistiques/Statistiques.vue'
import User from '@/views/User/User.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/statistiques',
    name: 'Statistiques',
    component: Statistiques,
    meta: {
      guest: false
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      guest: false
    }
  }
]

const router = new VueRouter({
  routes
})

// Route par défaut
const defaultRoute = "User";

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('access_token');
  
  // Si la route n'existe pas
  if (to.matched.length <= 0) {
    next({ name: 'login'})
  }
  // Si la route a besoin d'authentification
  else if (to.matched.some(record => !record.meta.guest)) {
    // Si l'user n'est pas auth
    if (token == null) {
      next({ name: 'login'})
    }
    else {
      next()
    }
  } else {
    // Si l'utilisateur n'est pas auth c'est parfait
    if(token == null) {
      next()
    }
    // Sinon il faut afficher la route par défaut
    else{
      next({ name: defaultRoute})
    }
  }
})

export default router