import AuthServices from '@/services/auth.js';

// initial state
const state = () => ({
  access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
});

// getters
const getters = {
  getAccessToken: (state) => {
    return state.access_token;
  },
  getUser: (state) => {
    return state.user;
  }
}

// actions
const actions = {
  login({ commit }, payload) {
    const { email, password } = payload;

    return new Promise((resolve, reject) => {
      try {
        AuthServices.login(email, password).then((response) => {
          commit('setAccessToken', response.access_token);
          commit('setUser', response.user);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');

    window.location.reload();
  }  
}

// mutations
const mutations = {
  setAccessToken(state, access_token) {
    state.access_token = access_token;
    localStorage.setItem('access_token', access_token);
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}