import UserServices from '@/services/user.js';

// initial state
const state = () => ({
  users: [],
  countAll: null,
  countToday: null,
  count30: null
});

// getters
const getters = {
  getAll: (state) => {
    return state.users;
  },
  getCountAll: (state) => {
    return state.countAll;
  },
  getCountToday: (state) => {
    return state.countToday;
  },
  getCount30: (state) => {
    return state.count30;
  }
}

// actions
const actions = {
  getAll({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      try {
        UserServices.getAll().then((response) => {
          commit('setUsers', []);

          // bind des logs avec les users
          response.forEach(element => {
            // recherche des logs associés
            const logs = rootGetters['log/getAllLogs']?.filter(el => el.user_id == element.user_id);
            
            if (logs) {
              element.log_callCounter = logs.filter(el => el.title == 'call').length;
              
              element.electron = false;
              element.mobile = false;
              element.web = false;

              const appMounted = logs.filter(el => el.title == 'appMounted');
              appMounted.forEach(mounted => {
                const data = typeof mounted.data == "string" ? JSON.parse(mounted.data) : mounted.data;
                
                switch (data.platform) {
                  case "Desktop":
                    element.electron = data.version;
                    break;

                  case "Cordova":
                    if (data.vendor && data.vendor == "Apple Computer, Inc.") {
                      element.mobile = "iOS " + data.version;
                    }
                    else {
                      element.mobile = "Android " + data.version;
                    }
                    
                    break;

                  case "Web":
                    element.web = data.version;
                    break;
                    
                  default:
                    break;
                }
              });

              element.log_appMountedCounter = appMounted.length;
              element.log_errorCounter = logs.filter(el => el.title.startsWith("error_")).length;
            }

            commit('setUser', element);
          });

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  getCountAll({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        UserServices.getCount("all").then((response) => {
          commit('setCountAll', response);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  getCountToday({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        UserServices.getCount("today").then((response) => {
          commit('setCountToday', response);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  getCount30({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        UserServices.getCount(30).then((response) => {
          commit('setCount30', response);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setUser(state, user) {
    const exist = state.users.find(el => el.id == user.id);

    if (!exist) {
      state.users.push(user);
    }
  },
  setUsers(state, users) {
    state.users = users;
  },
  setCountAll(state, countAll) {
    state.countAll = countAll;
  },
  setCountToday(state, countToday) {
    state.countToday = countToday;
  },
  setCount30(state, count30) {
    state.count30 = count30;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}