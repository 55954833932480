<template>
  <v-app id="inspire">
    <Navigation v-if="!isLogin"></Navigation>
    
    <v-main>
      <Background v-if="isLogin" />
      <vue-page-transition v-if="isLoaded" name="fade" class="h-100 center-y-parent">
        <router-view></router-view>
      </vue-page-transition>
    </v-main>

    <LoadingMask :overlay="getMask" />
    <ErrorPopup :overlay="getError" />

    <notifications position="top center" group="app" />

    <Logout />
  </v-app>
</template>

<script>
import LoadingMask from '@/components/App/Mask.vue'
import ErrorPopup from '@/components/App/Error_Popup.vue'
import Background from '@/components/App/Background.vue'
import Logout from '@/components/App/Logout.vue'
import Navigation from '@/components/App/Navigation.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: { LoadingMask, ErrorPopup, Background, Logout, Navigation },

  computed: {
    ...mapGetters({
      getAccessToken: 'user/getAccessToken',
      getMask: 'app/getMask',
      getError: 'app/getError',
      getMenu: 'app/getMenu'
    }),
    isLogin() {
      return this.$route.name == 'login' && this.getAccessToken == null;
    }
  },


  data: function() {
    return {
      isLoaded: false
    }
  },

  methods: {
    
  },

  mounted: async function() {
    // Si ce n'est pas la route login
    if (this.$route.name != "login") {
      // Si pas de data de connexion, logout
      if (this.getAccessToken == null) {
        this.$store.dispatch('user/logout');
        return;
      }
      this.$store.commit('app/mask');
      this.$store.dispatch('log/getAll').finally(() => {
        this.$store.commit('app/unmask');
        this.isLoaded = true;
      });
    }
    else {
      this.isLoaded = true;
    }
  }
};
</script>
<style>
  .center-x {    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-y {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center-y-parent {
    position: relative;
  }

  .center-y-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .center-xy {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }
</style>
