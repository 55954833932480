<template>
  <div class="mx-4 my-4">
    <div class="text-h5">Tableau de bord</div>
    <Statistique />
  </div>
</template>

<script>

import Statistique from './components/Statistique.vue';

export default {
  name: 'Home',

  components: { Statistique },

  data: function() {
    return {
    }
  },

  methods: {

  }
}
</script>
<style>

</style>