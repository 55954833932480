<template>
  <LineChart v-if="load" :data="data" :options="options" />
</template>

<script>

import LineChart from '@/components/LineChart.vue';

export default {
  name: 'Chart',

  components: { LineChart },

  props: {
    load: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    options: {
      type: Object
    }
  }
}
</script>
<style>

</style>