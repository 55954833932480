import LogService from '@/services/log.js';

// initial state
const state = () => ({
  logs: null
});

// getters
const getters = {
  getAllLogs: (state) => {
    return state.logs;
  },
  getCallLogs: (state) => {
    return state.logs ? state.logs.filter(el => el.title == "call") : [];
  },
  getCallLogCount: (state) => {
    return state.logs ? state.logs.filter(el => el.title == "call").length : 0;
  },
  getMountedLogs: (state) => {
    return state.logs ? state.logs.filter(el => el.title == "appMounted") : [];
  },
  getMountedLogCount: (state) => {
    return state.logs ? state.logs.filter(el => el.title == "appMounted").length : 0;
  },
  getErrorLogs: (state) => {
    return state.logs ? state.logs.filter(el => el.title.startsWith("error_")) : [];
  },
  getErrorLogCount: (state) => {
    return state.logs ? state.logs.filter(el => el.title.startsWith("error_")).length : 0;
  }
}

// actions
const actions = {
  getAll({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        LogService.getAll().then((response) => {
          commit('setLogs', response);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
}

// mutations
const mutations = {
  setLogs(state, logs) {
    state.logs = logs;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}